import React from "react"
import { Link } from "gatsby"

import Logo from "../images/logo.png"
import mobileImg from "../images/mobile-img.png"

import fbIcon from "../images/fb-icon.svg"
import twitterIcon from "../images/twitter.svg"
import instacon from "../images/insta-icon.svg"

import mapIcon from "../images/map.svg"

import video from "../images/video-icon.svg"
import phoneIcon from "../images/phone-icon.svg"
import chatIcon from "../images/chat-icon.svg"

import lineImage from "../images/line2.png"
import lineLeft from "../images/left-line.svg"
import lineRight from "../images/right-line.svg"

import mailIcon from "../images/mail-icon.svg"
import callIcon from "../images/call-icon.svg"
import livechat from "../images/live-chat-icon.svg"

import Layout from "../components/layout"
import icon from "../images/vehical-info.svg"
import icon1 from "../images/your-info.svg"
import icon2 from "../images/customize-plan.svg"
import icon3 from "../images/create-account.svg"
import icon4 from "../images/payment-info.svg"
import icon5 from "../images/confirm-icon.svg"


import icon6 from "../images/repair1-icon.svg"
import icon7 from "../images/repair2-icon.svg"
import icon8 from "../images/repair3-icon.svg"
import icon9 from "../images/repair4-icon.svg"
import icon10 from "../images/repair5-icon.svg"

import image1 from "../images/core.png"
import image2 from "../images/comfort.png"
import image3 from "../images/convenience.png"
import image4 from "../images/icon-7.png"
import newslatter from "../images/newslatter.svg"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import cloud from "../images/cloud.svg"
import rain from "../images/rain.svg"
import SEO from "../components/seo"

const IndexPage = (props) => (
  <Layout rest={props} layoutType="main">
    <SEO title="Home" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
    <div className="chat-content" style={{ display: 'none' }}>
      <div className="chat-content-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
          <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
              <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
      <div className="chat-box">

        <div className="chatbox-inner">
          <div className="chat-profile">
            <img className="" src={chatProfile} alt="" />
          </div>
          <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
          <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
        </div>
        <img className="chat-bottom" src={chatbottom} alt="" />
      </div>

    </div>

    {/* --------------banner--------- */}
    <section className="banner fontChange">
      <div className="banner-inner">
        <img src={cloud} alt="" className="cloud-bg" />
        <img src={rain} alt="" className="rain-bg" />
        <div className="container">
          <h1>We're changing the way you
          Protect Your Vehicle</h1>
          {/* <h5>Ultimate flexibility and the lowest prices, entirely online.</h5> */}
          <p>Ultimate flexibility and the lowest prices, entirely online.</p>
          <Link to="/vehicleInfo/" className="btn primary">Get your Price</Link>
        </div>
      </div>
    </section>

    {/* --------------- process-work-container--------- */}
    <section className="process-work-container fontChange">
      <div className="container">
        <h3 className="title">How the Process Works</h3>
        <p className="subtitle">Our customized process allows you to buy coverage specifically for your needs and driving habits.</p>
        <div className="work-content">
          <div className="card first">
            <div className="header">
              <span> <img src={icon} alt="Logo" /></span>

            </div>
            <h4>Vehicle Info</h4>
            <p>Providing your vehicle’s year, make, model, and mileage helps us narrow down your options.</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon1} alt="Logo" /></span>
            </div>
            <h4>Your Info</h4>
            <p>Providing us your email and phone number (optional) allows you to save your quote and come back later.</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon2} alt="Logo" /></span>
            </div>
            <h4>Customize Your Plan</h4>
            <p>We help you customize the plan to best fit your needs, driving habits, and budget.</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon3} alt="Logo" /></span>
            </div>
            <h4>Create Account</h4>
            <p>Creating an account gives you the option to save multiple quotes and then buy your plan.</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon4} alt="Logo" /></span>
            </div>
            <h4>Payment Info</h4>
            <p>Simply provide your payment information and select the terms that best fit your needs.</p>
          </div>
          <div className="card last">
            <div className="header">
              <span> <img src={icon5} alt="Logo" /></span>
            </div>
            <h4>Confirm</h4>
            <p>Confirm your information and make sure you’ve selected the right plan.  We’ll do the rest!</p>
          </div>
        </div>
        <div className="slider-point">
          <span className="active"></span>
          <span></span>
          <span></span>
        </div>
        <div className="btn-container">
          <button className="btn btn-outline"><img src={phoneIcon} alt="Logo" /> Call us</button>
          {/* <button >Get Your Price</button> */}
          <Link className="btn primary get-price" to="/vehicleInfo/">Get Your Price</Link>
          <button className="btn btn-outline"><img src={chatIcon} alt="Logo" /> Chat with us</button>
        </div>
      </div>

    </section>

    <section className="shoping-container fontChange">
      <div className="container">
        <div className="shoping-content">
          <div className="left">
            <h3 className="shoping-title">Shopping is simple from your phone!</h3>
            <p className="subtitle">We put all available coverages in one place.</p>

            <div className="m-shoping-part-img">
              <img src={mobileImg} alt="Logo" />
            </div>

            <div className="input-container">
              <img className="map-img" src={mapIcon} alt="map" />
              <input type="text" placeholder="Enter your zip code here" />
              <button className="btn primary" type="button">GET STARTED</button>
              <label>
                <b>Best Price Guarantee</b>
                <Link to="#">How does it work?</Link>
              </label>
            </div>

          </div>
          <div className="shoping-part-img">
            <img src={mobileImg} alt="Logo" />
          </div>

        </div>

      </div>
      <div className="down-img">
        <img className="left-line" src={lineLeft} alt="line" />
        <img className="right-line" src={lineRight} alt="line" />
      </div>

    </section>

    <section className="monthly-sub-container fontChange">
      <div className="container">
        <h3 className="title">Killer Prices, Monthly Subscription</h3>
        <p className="subtitle">Choose your coverage and deductible.</p>
        <div className="subscription-card">
          <div className="card">
            <div className="inner-card">
              <h4>Powertrian<br /> Plus</h4>
              <p>Monthly, from</p>
              <p className="amount"><small>$</small>49</p>
              {/* <button className="btn">Get Your Price</button> */}
              <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>

            </div>

          </div>
          <div className="card">
            <div className="inner-card">
              <h4>Almost<br /> Comprehensive</h4>
              <p>Monthly, from</p>
              <p className="amount"><small>$</small>69</p>
              {/* <button className="btn">Get Your Price</button> */}
              <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>
            </div>

          </div>
          <div className="card">
            <div className="inner-card">
              <h4>Comprehensive</h4>
              <p>Monthly, from</p>
              <p className="amount"><small>$</small>99</p>

              {/* <button className="btn">Get Your Price</button> */}
              <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>
            </div>

          </div>
        </div>
        <p className="insured-text"><b>Vehicle still has factory warranty? </b><Link to="/">Click here to compare how much you’ll save by purchasing now.</Link></p>
        <p className="insured-text"><b>Already have a service contract?</b> <Link to="/">Click here and we’ll take care of it. </Link></p>
      </div>
    </section>

    <section className="covered-container fontChange">
      <div className="container">
        <h3 className="title">What's Covered?</h3>
        <p className="subtitle">We think it should be simple: Core, Comfort, Convenience.*</p>
        <div className="covered-card">
          <div className="card first">
            <div className="inner-card">
              <div className="header">
                <img src={image1} alt="image1" />
              </div>
              <div className="card-body">
                <h4>Core</h4>
                <p>Core components are the things that make your car go and are typically the costliest to repair. Examples include your engine and transmission.</p>
              </div>

            </div>
          </div>
          <div className="card">
            <div className="inner-card">
              <div className="header">
                <img src={image2} alt="image1" />
              </div>
              <div className="card-body">
                <h4>Comfort</h4>
                <p>Comfort components allow you to drive comfortably and include things like your air conditioning and suspension.</p>
              </div>

            </div>
          </div>
          <div className="card last">
            <div className="inner-card">
              <div className="header">
                <img src={image3} alt="image1" />
              </div>
              <div className="card-body">
                <h4>Convenience</h4>
                <p>Convenience are things that make driving easy. <br />Things like navigation, back up cameras, and Bluetooth.  Convenience also makes life easy when a breakdown occurs by providing towing and a rental car.</p>
              </div>

            </div>
          </div>
        </div>
        <div className="slider-point">
          <span className="active"></span>
          <span></span>
          <span></span>
        </div>
        {/* <button className="btn get-price-btn">Get your Price</button> */}
        <Link className="btn primary get-price-btn" to="/vehicleInfo">Get Your Price</Link>
      </div>
    </section>

    {/* -------- */}
    <section className="process-work-container extra-benefits fontChange">
      <div className="container">
        <h3 className="title">Extra Benefits</h3>
        <p className="subtitle">Extra benefits are things that make the repair process easier.</p>
        <div className="work-content">
          <div className="card">
            <div className="header">
              <span> <img src={icon6} alt="Logo" /></span>
            </div>
            <p>Choose your repair shop.  If you don’t know one, call us, we’ll help!</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon7} alt="Logo" /></span>
            </div>
            <p>Roadside assistance is provided just in case you get stuck on the side of the road.</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon8} alt="Logo" /></span>
            </div>
            <p>Unlike others, all of our coverages are offered on a monthly basis, so you’re not locked in! You can also modify your coverage whenever you want.</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon9} alt="Logo" /></span>
            </div>
            <p>You choose the deductible that best fits your budget.</p>
          </div>
          <div className="card last">
            <div className="header">
              <span> <img src={icon10} alt="Logo" /></span>
            </div>
            <p>If you’re car is stuck in the shop, we’ll take care of the rental car.<sup className="small-1">#1</sup></p>
          </div>
        </div>
        <div className="slider-point">
          <span className="active"></span>
          <span></span>
          <span></span>
        </div>
        <div className="btn-container">
          {/* <button className="btn get-price-btn">Get Your Price</button> */}
          <Link className="btn primary get-price-btn" to="/vehicleInfo">Get Your Price</Link>
        </div>
      </div>

    </section>


    <section className="payClaims-container fontChange">
      <div className="container">
        <div className="left">
          <img src={image4} alt="Logo" />
        </div>
        <div className="right">
          <div className="payClaims-content">
            <h3 className="title">We're Here to Pay Claims and Keep You on the Road</h3>
            <p className="">Most insurance companies make money by managing
claims expenses.  Not us.  If we collect more than we pay out,
we put some money back into your pocket.</p>
            <div>
              {/* <button className="btn get-price-btn">Get Your Price</button> */}
              <Link className="btn primary get-price-btn" to="/vehicleInfo">Get Your Price</Link>
            </div>

          </div>
        </div>
      </div>
    </section>


    <section className="newslatter-container fontChange">
      <div className="container">
        <div className="newslatter-content">
          <div className="left">
            <img src={newslatter} alt="newslatter" />
          </div>
          <div className="right">
            <h3 className="title">Join our newsletter</h3>
            <p className="">Get our best tips & advice in your inbox</p>
            <div className="text-field">
              <input type="text" placeholder="Enter your e-mail Address" />
              <button className="btn join-btn primary">Join</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="break-section fontChange">

      <img src={lineImage} alt="line" />
    </div>

    <section className="contact-container fontChange">
      <div className="container Footer-container">
        <div className="logo-container">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="contact-inner">
          <div className="first-col">
            <ul>
              <li> <img src={mailIcon} alt="mail" /> <p>help@servicecontract.com</p>
                <span>24/7 -  Available when you need us</span>
              </li>
              <li> <img src={callIcon} alt="call" /><p>1-800-800-3629</p></li>
              <li> <img src={livechat} alt="liev chat" /><p>Live Chat</p></li>
            </ul>
          </div>
          <div className="second-col">
            <ul>
              <li><Link to="/vehicleInfo">Get Your Price</Link></li>
              <li><Link to="">What's Covered</Link></li>
              <li><Link to="">Extra Benefits</Link></li>
              <li><Link to="">Claim</Link></li>
              {/* <li><Link to="">Our Partners</Link></li> */}
              <li><Link to="">Who We Are</Link></li>
              <li><Link to="">FAQs</Link></li>
              {/* <li><Link to="">API</Link></li> */}
            </ul>
          </div>
          <div className="third-col">
            <ul>
              <li><Link to="">Who We Are</Link></li>
              {/* <li><Link to="">Blog</Link></li> */}
              <li><Link to="">Extra Benefits</Link></li>
              <li><Link to="">Contact</Link></li>
              {/* <li><Link to="">Join the Team</Link></li> */}
              <li><Link to="/legalstuff">Legal Stuff</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
            </ul>
          </div>
          <div className="fourth-col">
            {/* <div className="store-icon">
              <h6>Get Our App</h6>
              <span><img src={appStoreIcon} alt="Apple store" /></span>
              <span><img src={googlePlayIcon} alt="Googole play store" /></span>
            </div> */}

            <div className="follow-icon">
              <h6>Follow Us</h6>
              <span><img src={fbIcon} alt="facebook" /></span>
              <span><img src={twitterIcon} alt="Twitter" /></span>
              <span><img src={instacon} alt="instagra," /></span>
              <span><img src={video} alt="youtube" /></span>
            </div>

          </div>
        </div>

      </div>


    </section>
    <section className="copyright-container fontChange">
      <div className="container">
        <div className="copyright">
          <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
          <p>#1 If your vehicle experiences a breakdown and is inoperable or unsafe to drive and needs to be held for at least one day by a repair facility for a covered repair, we will reimburse you the cost to rent a vehicle from a licensed rental agency at a rate not to exceed thirty-five dollars ($35.00) per day.  Rental reimbursement is limited to a maximum of five (5) days per breakdown of the same covered part and an aggregate maximum of thirty (30) days as limited by the rental reimbursement maximum.</p>
        </div>
      </div>

    </section>

  </Layout>
)

export default IndexPage
